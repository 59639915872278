<template>
  <div class="kpi-wrapper">
    <kpi-header />
    <kpi-navigation class="nav-position" />
    <kpi-filter />
    <router-view />
  </div>
</template>

<script>
import KpiHeader from './KpiHeader.vue'
import KpiNavigation from './KpiNavigation.vue'
import KpiFilter from './KpiFilter.vue'

export default {
  name: 'KpiView',
  components: {
    KpiHeader,
    KpiNavigation,
    KpiFilter
  }
}
</script>

<style lang="scss" scoped>
div.kpi-wrapper {
  width: 100%;
  padding-top: 72px; // because of header

  .nav-position {
    margin-top: -32px;
  }
}

@media screen and (max-width: $mobile) {
  div.kpi-wrapper {
    padding: 64px 0 100px 0;

    .nav-position {
      margin-top: 0;
    }
  }
}
</style>
