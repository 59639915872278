<template>
  <div class="kpi-header">
    <div class="kpi-header__content">
      <div class="left">
        <img
          :src="ButtonIcon"
          alt="Back"
          class="back-button"
          @click="$router.push({ name: 'Dashboard' })"
        >
        <h4>{{ $t('kpi.header.heading') }}</h4>
      </div>
      <div class="right">
        <custom-button class="export-button">
          <template v-slot:leftIcon>
            <img
              :src="ExportIcon"
              alt="Plus icon"
            >
          </template>
          {{ $t('kpi.header.export') }}
        </custom-button>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonIcon from '@/assets/arrow_button.svg'
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'
import ExportIcon from '@/assets/icons/export.svg'

export default {
  name: 'KpiHeader',
  components: {
    CustomButton
  },
  data () {
    return {
      ButtonIcon,
      ExportIcon
    }
  }
}
</script>

<style lang="scss" scoped>
div.kpi-header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 192px;
  background-color: $primary-darkgrey;
  padding: 48px 166px;

  div.kpi-header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1108px;
    height: 64px;

    div.left {
      display: flex;
      align-items: center;

      img.back-button {
        cursor: pointer;
        margin-right: 32px;
      }

      h4 {
        color: $primary-white;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.kpi-header {
    height: 96px;
    padding: 24px;

    div.kpi-header__content {
      height: unset;

      div.left {
        img.back-button {
          display: none;
        }

        h4 {
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.5px;
        }
      }

      div.right {
        .export-button {
          display: none;
        }
      }
    }
  }
}
</style>
