<template>
  <nav class="kpi-navigation">
    <ul class="kpi-navigation-list">
      <li
        v-for="(route, i) in kpiNav"
        :key="i"
        class="kpi-navigation-item"
        @click.stop="$router.push({ name: route.where })"
      >
        <span
          class="navigation-text"
          :class="{'active-route': $route.name === route.where}"
        >
          {{ route.text }}
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'KpiNavigation',
  data () {
    return {
      kpiNav: [
        { text: this.$t('kpi.navigation.basic'), where: 'BasicKpi' },
        { text: this.$t('kpi.navigation.machines'), where: 'MachinesKpi' },
        { text: this.$t('kpi.navigation.users'), where: 'UsersKpi' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
nav.kpi-navigation {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 166px;

  ul.kpi-navigation-list {
    list-style-type: none;
    display: flex;
    width: 100%;
    max-width: 1108px;
    padding: 0;

    li.kpi-navigation-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 368.67px;
      padding: 16px;
      background-color: $primary-white;
      border-right: 1px solid $primary-lightgrey;

      &:last-child {
        border-right: none;
      }

      span.navigation-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.25px;
        cursor: pointer;

        &.active-route {
          color: $primary-lightorange;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  nav.kpi-navigation {
    padding: 0;

    ul.kpi-navigation-list {
      li.kpi-navigation-item {
        span.navigation-text {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
