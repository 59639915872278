<template>
  <div class="kpi-filter">
    <div class="filter-select">
      <span class="filter">{{ $t('kpi.filter.filter') }}:</span>
      <custom-select-input
        v-model="selectedWeek"
        :options="filterOptions"
        class="select-input"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomSelectInput from '@/components/GlobalComponents/CustomSelectInput.vue'

export default {
  name: 'KpiFilter',
  components: {
    CustomSelectInput
  },
  data () {
    return {
      selectedWeek: null,
      filterOptions: [
        {
          value: 1,
          label: '1KW'
        },
        {
          value: 2,
          label: '2KW'
        },
        {
          value: 3,
          label: '3KW'
        },
        {
          value: 4,
          label: '4KW'
        },
        {
          value: 5,
          label: '5KW'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('KpiDateRangeFilter', ['getWeek'])
  },
  watch: {
    selectedWeek (newVal, oldVal) {
      this.changeWeek()
    }
  },
  created () {
    if (this.selectedWeek === null) {
      this.selectedWeek = 1
    } else {
      this.selectedWeek = this.getWeek
    }
  },
  methods: {
    changeWeek () {
      this.$store.commit('KpiDateRangeFilter/SET_WEEK', this.selectedWeek)
    }
  }
}
</script>

<style lang="scss" scoped>
div.kpi-filter {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 32px 166px;

  div.filter-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 1108px;

    span.filter {
      font-weight: 500;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.15px;
      margin-right: 16px;
    }

    .select-input {
      max-width: 262px;
    }
  }
}
@media screen and (max-width: $mobile) {
  div.kpi-filter {
    padding: 16px;
  }
}
</style>
